:root {
  --primary-400: #f7f7f7;
  --primary-500: #F5F8F9;
  --primary-600: #E7EEF0;
  --primary-700: #D9E5E9;

  --black: #000000;
  --white: #FFFFFF;
  --red-100: #f3b6b6;
  --red-200: #ee9c9c;
  --red-300: #e88282;
  --red-400: #e36868;
  --red-500: #de4e4e;
  --red-600: #d43434;


  --white-LowOpacity: rgba(255, 255, 255, 0.16);

  --orangeAccent-100: #FFEFE2;
  --orangeAccent-200: #FCEBCF;
  --orangeAccent-300: #FFB388;
  --orangeAccent-500: #EF8144;

  --greenAccent-100: #EFFCEF;
  --greenAccent-200: #CDF59A;
  --greenAccent-300: #A0E7A1;
  --greenAccent-500: #5DC250;

  --gray-200: #F5F5F5;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #969696;
  --gray-600: #6E6E6E;
  --gray-700: #4c4c4c;
  --gray-800: #3A3A3A;
  --gray-900: #292929;
}
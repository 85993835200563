.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* .primarySide {
  display: flex;
  background: #063e40;
  background: linear-gradient(90deg,#063e40 0,#309367 41.37%,#a9d59e 100%);
  align-items: center;
  justify-content: center;
  padding: 0 6%;
  font-size: 1.1rem;
} */
.primarySide {
  display: flex;
  background: #063e40;
  background: linear-gradient(90deg, #063e40 0, #309367 41.37%, #a9d59e 100%);
  align-items: center;
  justify-content: center;
  padding: 0 6%;
  font-size: 1.1rem;
  /* Set background image */
  background-image: url('../../img/bg-unauthorized-background.jpg');
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  /* Optionally, you can add other background properties like repeat, attachment, etc. */
}


.logoStyle {
  max-width: 100%;
  padding: 40px;
  /* background-color:white; */
  /* filter: blur(4px); */
  background-color: rgba(250, 250, 250, 0.75);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  border-color: rgb(79, 79, 107, 0.3);
  border-style: solid;
  border-width: 1px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.title {
  font-weight: 700;
  margin-top: 4%;
}

.description {
  text-align: justify;
  font-size: 1rem;
  margin-bottom: 5%;
  /* overflow: scroll; */
}

.whiteSide {
  background-color: offwhite;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 34px);
}


.labels {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 25px 0;
}
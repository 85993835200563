@import '../../colors.css';

.logo {
    background-color: var(--black);
    color: white;
    border-radius: 25%;
    min-width: 2.5rem;
    min-height: 2.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: 1rem;
}

.headerContainer {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    padding: 10px;
}




.boldText {
    font-weight: 700;
    font-size: 1rem;
    border-right: 3px solid white;
}

.menuItem {
    color: white;
}


.menuItem:hover {
    color: black;
    background-color: rgb(200, 200, 200)
}


.subMenu {
    color: white;
}


.subMenu:hover {
    color: black;
    background-color: rgb(200, 200, 200)
}



.subMenuItem {
    color: black;
    background-color: rgb(200, 200, 200)
}

.subMenuItem:hover {    
    color:black;
}

a:focus {
    outline: none;
}


/* .menu-label {
    font-size: 1rem;
    font-weight: 700;
    color:white;

}

.menu-label:hover {
    color:black !important;

} */

/* button {
    right: 20px;
} */

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}


.squareImageContainer {
    width: 100%;
    height: 100%;
  }

.squareImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.imageWithFixedDimension {
  object-fit: cover;
  border-radius: 5px;
}
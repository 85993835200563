.MuiDataGrid-virtualScrollerRenderZone {
  overflow-y: auto !important; 
}

 
.MuiDataGrid-virtualScroller{
   min-height: 100px !important;  /*  needed for empty table without auto height */
}

.MuiDataGrid-main{
  border: 1px solid #d1d1d1 !important;
  border-radius: 5px;
  min-height: 100px !important;   /*  needed for empty table without auto height */
}

.MuiDataGrid-cell {
  min-height: 70px;
  max-height: 70px;
}

@media (max-height: 900px) {
  /* .MuiDataGrid-root .MuiDataGrid-row { */
  .MuiDataGrid-cell {
    min-height: 70px !important;
    max-height: 70px !important;
  }

  /* .MuiDataGrid-row {
    min-height: 60px !important;
    max-height: 60px !important;
  } */
}
  
@media (min-height: 901px) {
  /* .MuiDataGrid-root .MuiDataGrid-row { */
  .MuiDataGrid-cell {
    min-height: 110px !important;
    max-height: 110px !important;
  }

  /* .MuiDataGrid-row {
    min-height: 100px !important;
    max-height: 100px !important;
  } */
}
.custom-wrapper {
	width: 100% !important;
	display: block !important;
	margin-bottom: 25px !important;
	min-height: 220px !important;
}

.custom-editor {
	min-height: 200px !important;
	
	padding-left: 16px !important;
	padding-right: 16px !important;
	border-radius: 2px !important;
}

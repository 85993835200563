.app,
.content {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow: auto;
  font-size: 1rem;
}

.app {
  display: flex;
  position: relative;
}

.p {
  margin: 0 !important;
}

.content {
  padding: 0 30px;
}
